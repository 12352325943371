import { processingQuote } from './lh__bquote';
import { processingButton } from './core__buttons';
import { processingParagraph } from './core__paragraph';
import { processingRef } from './lh-reference';
import { processingImage } from './core__image';
import { processingList } from './core__list';
import { processingShortCode } from './core__shortcode';
import { processingBox } from './lh__greybox';
import { processingCode } from './core__code';
import { processingEmailBlock } from './lh__email-block';
import { processingGroup } from './core__group';
import { processingTable } from './core__table';
import { processingUsefulInfo } from './lh__ads-page-useful-info';
import { processingHeading } from './core__heading';
import { processingSeparator } from './core__separator';
import { processingLink } from './a';
import { processingEmbed } from './core__embed';

const processingFunctionByContentType = {
  core__buttons: processingButton,
  core__button: processingButton,
  core__separator: processingSeparator,
  core__paragraph: processingParagraph,
  'lh-reference': processingRef,
  core__image: processingImage,
  core__list: processingList,
  core__embed: processingEmbed,
  core__heading: processingHeading,
  core__shortcode: processingShortCode,

  a: processingLink,

  'lh__speech-bquote': processingQuote,
  'lh__characters-list': processingQuote,
  'lh__author-bquote': processingQuote,
  core__pullquote: processingQuote,
  'lh__cut-bquote': processingQuote,
  'lh__simply-bquote': processingQuote,
  'lh__review-bquote': processingQuote,

  lh__greybox: processingBox,
  lh__whitebox: processingBox,
  core__code: processingCode,
  'lh__email-block': processingEmailBlock,
  core__group: processingGroup,
  core__table: processingTable,
  'lh__ads-page-useful-info': processingUsefulInfo,
} as Record<string, Function>;

export const processingContent = (content: TContent): TContent => {
  const processingFunction = processingFunctionByContentType[content.type];

  return processingFunction
    ? processingFunction(content)
    : content;
};
