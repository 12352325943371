import type { Context } from '@nuxt/types';
import { CLEAR_USER } from '~/constants';
import { clearUserToken, isRequiresAuth } from '@/utils';

export function authErrorHandler (
  error: any,
  ctx: Pick<Context, 'store' | 'redirect' | 'route'>,
  moduleName: string = 'auth.ts',
) {
  const { store, redirect, route } = ctx;

  const safeRedirect = (path: string) => process.client ? (location.href = path) : redirect({ path });

  if (error.response) {
    // Запрос был сделан, и сервер ответил кодом состояния
    // который выходит за пределы диапазона 2xx
    const {
      message,
    } = error;

    const status = error?.response?.status || error?.request?.status;

    switch (status) {
      case 401:
      case 403:
        clearUserToken();
        store.dispatch(CLEAR_USER);
        return isRequiresAuth(route) ? safeRedirect('/') : '';
      default:
        // eslint-disable-next-line no-console
        console.error(`Error response ${moduleName}: ${message}`);
    }
  } else if (error.request) {
    // Запрос был сделан, но ответ не получен
    // `error.request` — это экземпляр XMLHttpRequest в браузере и экземпляр
    // http.ClientRequest в node.js
    // eslint-disable-next-line no-console
    console.error(`Error request ${moduleName}: ${error.message}`);
  } else {
    // Произошло что-то при настройке запроса, вызвавшее ошибку
    // eslint-disable-next-line no-console
    console.error(`Error settings ${moduleName}: ${error.message}`);
  }
}
