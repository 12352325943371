export const processingScript = (content: TContent): TContent | undefined => {
  const scriptObject = content.children[0];

  if (scriptObject?.text?.includes('VK.Widgets.Post')) {
    const scriptText = scriptObject.text;
    const postArgs = scriptText.match(/Post\((.*?)\)/)?.[1].split(',');

    if (!postArgs) {
      return;
    }

    return {
      ...scriptObject,
      attributes: {
        providerNameSlug: 'vk',
        vid: postArgs[2],
        ownerId: postArgs[1],
        hash: postArgs[3].replace(/'/g, ''),
      },
      type: 'core__embed',
      parser_type: 'gutenberg_parser',
    };
  }

  return content;
};
