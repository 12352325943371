import { CreateElement, VNode, RenderContext } from 'vue';
import TheContent from '~/components/content/TheContent';

import { isArray, IN_BROWSER } from '~/utils';

// Все компоненты обязательно должны импортироваться асинхронно!
// https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
const LhCarousel = () => import('~/components/slider/LhCarousel');
const LhTopicsList = () => import('~/components/LhTopicsList');
const NewsletterSubscriptionFormContainer = () => import('~/containers/NewsletterSubscriptionFormContainer/NewsletterSubscriptionFormContainer.vue');
const TheTable = () => import('~/components/content/components/TheTable.vue');

// AMP
const AmpImageWrapper = () => import('~/components/AmpImageWrapper.amp/AmpImageWrapper.amp.vue');
const NewsletterSubscriptionFormContainerAmp = () => import(('~/containers/NewsletterSubscriptionFormContainer/NewsletterSubscriptionFormContainer.amp.vue'));
const AmpIframeWrapper = () => import('~/components/AmpIframeWrapper.amp/AmpIframeWrapper.amp.vue');

const components: Record<string, any> = {
  LhCarousel,
  LhTopicsList,
  'amp-image-wrapper': AmpImageWrapper,
  'amp-iframe-wrapper': AmpIframeWrapper,
  'lh__email-block': NewsletterSubscriptionFormContainer,
  'lh-email-block': NewsletterSubscriptionFormContainer,
  table: TheTable,

  // AMP
  'lh__email-block-amp': NewsletterSubscriptionFormContainerAmp,
};

function isNeedLazyLoading (type: TContent['type'], lazyLoadingEnabled: boolean): boolean {
  return ['img', 'source'].includes(type) && lazyLoadingEnabled;
}

function isNeedLazyloadClass (type: TContent['type']): boolean {
  if (IN_BROWSER) {
    return ['img'].includes(type);
  } else {
    return false;
  }
}

export const renderHTMLParser = (
  elem: TContent,
  h: CreateElement,
  context: RenderContext,
  lazyLoadingEnabled: boolean = false,
  location: string | null = null,
): VNode => {
  const { type, text, attributes, children } = elem;
  const node = components[type] || type;

  if (type === 'text' || type === '#text' || type === '#cdata-section') {
    // https://github.com/vuejs/vue/blob/dev/src/core/vdom/vnode.js#L81
    // @ts-ignore
    const createTextVNode: (val: string | number) => VNode = context._v;
    return createTextVNode(text);
  }

  if (isNeedLazyLoading(type, lazyLoadingEnabled)) {
    const { src, srcset, ...otherAttributes } = attributes;

    // See LH-1556
    if (process.server) {
      return h('noscript', {}, [
        h(node, {
          attrs: { ...attributes },
          style: attributes?.style ?? null,
        }),
      ]);
    }

    const calcLoading = isNeedLazyloadClass(type) ? 'lazy' : 'eager';

    const calcAttributes = {
      ...otherAttributes,
      src,
      srcset,
      loading: calcLoading,
    };

    return h(node, {
      attrs: calcAttributes,
      style: attributes?.style ?? null,
    });
  }

  let childNodes: VNode[] = [];
  if (isArray(children)) {
    childNodes = children.map((item: TContent) => {
      return h(TheContent, { props: { elem: item, lazyLoadingEnabled, location } });
    });
  }

  if (type === 'amp-youtube' || type === 'amp-viqeo-player') {
    delete attributes.src;
  }

  return h(
    node,
    {
      attrs: { ...attributes, location },
      style: attributes?.style ?? null,
    },
    childNodes,
  );
};
