import { isValidCarousel } from './lh__carousel';
import { isValidButton } from './core__buttons';
import { isValidTable } from './core__table';
import { isValidGroup } from './core__group';
import { isValidGallery } from './core__gallery';
import { isValidEmbed } from './embed';
import { isValidReadAlso } from './lh__read-also';

const validateFunctionByContentType = {
  lh__carousel: isValidCarousel,
  'lh__read-also': isValidReadAlso,
  core__button: isValidButton,
  core__buttons: isValidButton,
  core__table: isValidTable,
  core__gallery: isValidGallery,
  core__group: isValidGroup,
  core__embed: isValidEmbed,
  '#comment': () => false,
} as Record<string, Function>;

export function isValidContent (content: TContent): boolean {
  const validateFunction = validateFunctionByContentType[content.type];

  return typeof validateFunction === 'function'
    ? validateFunction(content)
    : true;
};
